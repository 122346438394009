<template>
  <div id="historyData" v-loading.fullscreen.lock="loading" element-loading-text="请求中，请稍后" element-loading-background="rgba(255, 255, 255, 0.7)">
    <!-- 查询 -->
    <Query @query="query" @excel="excel"></Query>
    <div class="c-container-box">
      <!-- 选择时间 -->
      <!-- <el-time-picker is-range range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" v-model="time" format="HH:mm:ss" value-format="HHmmss" :editable="false" :clearable="false" @change="timeChange">
      </el-time-picker> -->
      <!-- 选择日期 -->
      <!-- <el-radio-group v-model="curDate">
        <template v-for="(item,index) of dateGroups">
          <el-radio-button :key="index" :label="item"></el-radio-button>
        </template>
      </el-radio-group> -->
      <!-- 数据展示 -->
      <!-- 历史数据 -->
      <template v-if="type=='sensor'">
        <el-tabs v-model="activeName" @tab-click="tabChange" style="margin: 8px 2px;">
          <el-tab-pane label="曲线图" name="chart">
            <template v-if="deviceModel =='swLora'  || deviceModel=='dmWater' || deviceModel =='dmMaster'">
              <el-tabs v-model="activeNodeName" @tab-click="handleClickTab">
                <el-tab-pane v-for="(node,i) of chartCounts" v-bind:key="i" :name="i.toString()">
                  <span slot="label" class="tab-node">{{deviceFields|nodeNameFilters(i)}}</span>
                  <div class="chart-container" v-if="activeNodeName==i">
                    <div class="chart" v-for="(item,index) of chartCount" :key="index">
                      <div style="width: 100%; height: 400px;" :id="`chart${index-1}`"></div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
              <div class="emty-class" v-if="deviceData.length == 0">暂无数据</div>
            </template>
            <template v-else>
              <div class="emty-class" v-if="chartCount == 0">暂无数据</div>
              <div class="chart-container" v-else>
                <div class="chart" v-for="(item,index) of chartCount" :key="index">
                  <div style="width: 100%; height: 400px;" :id="`chart${index-1}`">{{item}}</div>
                </div>
              </div>
            </template>

          </el-tab-pane>
          <el-tab-pane label="一览表" name="table">
            <template v-if="deviceModel =='swLora' || deviceModel =='dmWater' || deviceModel =='dmMaster'">
              <el-tabs v-model="activeTableName" @tab-click="handleClickTable">
                <el-tab-pane v-for="(node,i) of deviceData" v-bind:key="i" :name="i.toString()">
                  <span slot="label" class="tab-node">{{deviceFields|nodeNameFilters(i)}}</span>
                  <el-table :data="deviceTableFilter" v-if="activeTableName==i">
                    <el-table-column type="index" label="序号" width="100">
                      <template slot-scope="scope">
                        {{(page.curPage - 1) * page.size + (scope.$index + 1)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="time" label="时间" width="200"></el-table-column>
                    <el-table-column label="设备数据">
                      <template v-for="(item,index) of deviceFields">
                        <el-table-column v-if="!item.hide" :key="index">
                          <template slot="header">
                            <span>{{item.title}}</span>
                          </template>
                          <template slot-scope="scope">
                            {{filterValue(scope.row.datas,index)}}
                            <!-- 模拟数据用 -->
                            <!-- {{scope.row.datas}} -->
                          </template>
                        </el-table-column>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination background :page-sizes="[10, 15, 20, 25, 30]" :page-size="page.size" :current-page="page.curPage" layout="total, sizes, prev, pager, next" :total="node.length" v-show="node.length>0" @size-change="sizeChange" @current-change="pageChange">
                  </el-pagination>
                </el-tab-pane>
              </el-tabs>
              <div class="emty-class" v-if="deviceData.length == 0">暂无数据</div>
            </template>
            <template v-else>
              <el-table :data="deviceDataFilter">
                <el-table-column type="index" label="序号" width="100">
                  <template slot-scope="scope">
                    {{(page.curPage - 1) * page.size + (scope.$index + 1)}}
                  </template>
                </el-table-column>
                <el-table-column prop="time" label="时间" width="200"></el-table-column>
                <el-table-column label="设备数据">
                  <el-table-column v-for="(item,index) of deviceFields" :key="index">
                    <template slot="header">
                      <span>{{item.title}}</span>
                    </template>
                    <template slot-scope="scope">
                      {{filterValue(scope.row.datas,index)}}
                      <!-- 模拟数据用 -->
                      <!-- {{scope.row.datas}} -->
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table>
              <el-pagination background :page-sizes="[10, 15, 20, 25, 30]" :page-size="page.size" :current-page="page.curPage" layout="total, sizes, prev, pager, next" :total="deviceData.length" v-show="deviceData.length>0" @size-change="sizeChange" @current-change="pageChange">
              </el-pagination>
            </template>
          </el-tab-pane>
        </el-tabs>
      </template>
      <!-- 状态数据 -->
      <template v-if="type=='online'">
        <div class="typeOnline">
          <el-table :data="deviceDataFilter">
            <el-table-column type="index" label="序号" width="100">
              <template slot-scope="scope">
                {{(page.curPage - 1) * page.size + (scope.$index + 1)}}
              </template>
            </el-table-column>
            <el-table-column prop="time" label="时间" width="200"></el-table-column>
            <el-table-column prop="type" label="状态" style="text-align: left;">
              <template slot-scope="scope">
                <span v-if="scope.row.type=='online'">设备上线</span>
                <span v-if="scope.row.type=='offline'" style="color: #F2626D;">设备离线</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="datas" label="数据"></el-table-column> -->
          </el-table>
          <el-pagination background :page-sizes="[10, 15, 20, 25, 30]" :page-size="page.size" :current-page="page.curPage" layout="total, sizes, prev, pager, next" :total="deviceData.length" v-show="deviceData.length>0" @size-change="sizeChange" @current-change="pageChange">
          </el-pagination>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Query from '../../components/Query.vue';
let echarts = require('echarts'); // 引进echarts
export default {
  data() {
    return {
      type: 'sensor', //sensor传感器数据，online在线离线数据——后台历史数据请求类型：8传感器，10设备上线，11设备离线
      device: '', //设备
      deviceModel: '', //设备model
      date: [], //日期区间
      curDate: '', //当前选中（数据）日期,
      time: ['000000', '235959'], //时间区间
      // 设备字段/数据
      deviceFields: [],
      deviceData: [],
      // 图表个数/数据
      chartCount: 0,
      chartCounts: 0, //节点个数
      chartData: [],
      // page
      page: {
        size: 10,
        curPage: 1
      },
      activeName: 'chart', //图表表格视图切换
      activeNodeName: "0",
      activeTableName: "0",
      isCreateChart: false, //是否创建图表标记
      loading: false
    }
  },
  components: {
    Query
  },
  computed: {
    dateGroups() {
      if (this.date == null || this.date.length == 0) {
        return [];
      }
      let date = this.date;
      let dates = this.common.cGetDiffDate(date[0], date[1]);
      return dates;
    },
    // 表格单元格数据计算
    filterValue() {
      return (datas, field) => {
        let value = "";
        if (datas != undefined) {
          for (let item of datas) {
            if (item.key == field) {
              value = item.value;
              break;
            }
          }
        }
        return value;
      }
    },
    // 计算当前分页数据
    deviceDataFilter() {
      let page = this.page;
      let arr = this.deviceData.filter((item, index) => index >= ((page.curPage - 1) * page.size) && index <= ((
        page.curPage * page.size) - 1));
      return arr;
    },
    deviceTableFilter() {
      let page = this.page;
      let data = this.deviceData[this.activeTableName]
      let arr = data.filter((item, index) => index >= ((page.curPage - 1) * page.size) && index <= ((
        page.curPage * page.size) - 1));
      return arr;
    }
  },
  watch: {
    // 监听路由变化更新页面
    $route(to, from) {
      if (to.name.indexOf("HistoryData") != -1) {
        let param = to.query;
        this.type = param.type;
        this.$nextTick(() => {
          this.dataRequest();
        })
      }
    }
  },
  filters: {
    nodeNameFilters: function (val, i) {
      if (!val || !val.n) {
        return '节点'
      }
      return val.n.title + (i + 1)
    }
  },
  methods: {
    // 查询
    query(device, deviceModel, date) {
      //切换了设备，清空数据
      if (this.device !== device) {
        this.deviceFields = [];
        this.deviceData = []
      }
      this.device = device;
      this.deviceModel = deviceModel;
      this.date = date;
      this.curDate = date[0].substring(0, 4) + "-" + date[0].substring(4, 6) + "-" + date[0].substring(6);
      this.dataRequest();
    },
    // 导出到Excel
    excel(device, deviceModel, date) {
      // this.device = device;
      // this.deviceModel = deviceModel;
      // this.date = date;
      // console.log(33333, this.device, this.date);
      let json = {
        "cmd": "createHistoryFile",
        "uid": this.device,
        "startDate": this.date[0],
        "stopDate": this.date[1],
        "startTime": this.date[2],
        "stopTime": this.date[3],
        "fileExt": ".xlsx" //支持.csv和.xlsx,目前微信只支持.xlsx格式
      }
      this.ws.send(json);
      this.loading = true;
    },
    // 请求数据
    dataRequest() {
      let type = '8';
      switch (this.type) {
        case 'sensor':
          type = '8';
          break;
        case 'online':
          type = '10,11';
          break;
      }
      let json = {
        "cmd": "history2",
        "uid": this.device,
        "startDate": this.date[0],
        "stopDate": this.date[1],
        // "date": this.curDate.replace(/-/g, ""),
        "startTime": this.date[2],
        "stopTime": this.date[3],
        // "startTime": this.time[0],
        // "stopTime": this.time[1],
        "pageItemCount": '10000',
        "types": type,
        "timeOrder": "asc"
      }
      this.ws.send(json);
      this.loading = true;
      this.page.curPage = 1;
      this.isCreateChart = false;
      setTimeout(() => {
        this.loading = false;
      }, 5000)
    },

    // 日期改变
    dateChange() {
      this.dataRequest();
    },
    timeChange(time) {
      this.dataRequest();
    },

    // 分页——当前页改变
    sizeChange(size) {
      this.page.size = size;
      this.page.curPage = 1;
    },
    pageChange(curPage) {
      this.page.curPage = curPage;
    },

    // 曲线图/一览表切换
    tabChange() {
      if (this.activeName == "chart") {
        this.setChartCount(this.deviceData);
      }
    },
    //节点切换
    handleClickTab(tab, event) {
      this.isCreateChart = false;
      this.setChartCount(this.deviceData[tab.index]);
    },

    handleClickTable(tab, event) {
      this.page = {
        size: 10,
        curPage: 1
      }
    },

    // 当前路由——请求传感器数据
    setSensorData(res) {
      if (this.deviceModel == "") {
        this.deviceFields = [];
        this.deviceData = [];
        this.setChartCount(this.deviceData);
        return;
      }
      // 表头
      let profile = this.$store.state.profile;
      this.deviceFields = profile.profile.models[this.deviceModel].fields;
      // 数据
      let dataArr = new Array();
      for (let item of res.items) {
        let time = this.common.cComputationTime(item.time);
        let datetime = item.date.toString()
        datetime = datetime.substr(0, 4) + "-" + datetime.substr(4, 2) + "-" + datetime.substr(6, 2)
        let data;

        if (this.deviceModel == 'swLora' || this.deviceModel == 'dmWater' || this.deviceModel == 'dmMaster') {
          data = this.parseProfile.parseData2(this.deviceModel, item.data);
        } else {
          data = this.parseProfile.parseData(this.deviceModel, item.data);
        }
        dataArr.push({
          time: datetime + " " + time, //日期 时间
          datas: data,
        })
      }

      // 图表
      if (this.deviceModel == 'swLora' || this.deviceModel == 'dmWater' || this.deviceModel == 'dmMaster') {

        if (dataArr.length > 0) {
          //节点个数
          this.chartCounts = dataArr[0].datas.length
          let dataArr2 = new Array();
          for (let i = 0; i < this.chartCounts; i++) {

            let dataArr2child = new Array();
            dataArr.forEach(e => {
              dataArr2child.push({
                time: e.time,
                datas: e.datas[i],

              })
            })
            dataArr2.push(dataArr2child)
          }
          this.deviceData = dataArr2;
          this.setChartCount(dataArr2[0]);

        }

      } else {
        this.deviceData = dataArr;
        this.setChartCount(dataArr);
      }

    },
    // 当前路由——请求设备状态（在线离线）数据
    setOnlineData(res) {
      let dataArr = new Array();
      for (let item of res.items) {
        let time = this.common.cComputationTime(item.time);
        dataArr.push({
          time: time,
          type: item.type,
          datas: item.data
        })
      }
      this.deviceData = dataArr;
    },

    // 设置图表个数
    setChartCount(deviceData) {
      if (deviceData == void 0 || deviceData.length == 0) {
        this.chartCount = 0
        return;
      }
      // 如果当前视图图表不可见（或者图表已被创建），则不创建图表，因为echart在视图不可见时获取不到宽度
      if (this.activeName != "chart" || this.isCreateChart) {
        return;
      }
      // 计算图表创建数量
      let colors = this.common.cColors();
      let arr = new Array();
      let index = 0;
      //dmWater模型排除电量
      if (this.deviceModel == "dmWater" && this.deviceFields.b != "") {
        this.deviceFields.b.hide = true
      }
      for (let item in this.deviceFields) {
        // 用valueMap判断是否创建该字段的图表，若字段存在valueMap，则说明数据为状态
        // hide 隐藏类字段不创建图表
        if (!this.deviceFields[item].valueMap && !this.deviceFields[item].hide) {
          let obj = this.deviceFields[item];
          obj.field = item;
          obj.color = colors[index];
          arr.push(obj);
          index++;
        }
      }
      this.chartCount = arr;

      setTimeout(() => {
        // 初始化图表数据
        for (let i = 0; i < this.chartCount.length; i++) {
          let tData = this.chartCount[i];
          let xData = new Array();
          let yData = new Array();
          for (let item of deviceData) {
            xData.push(item.time);
            if (item.datas) {
              let loop = 0; // 标记，判断若该时间点没有数据，添加空数据（若不添加，数据错乱）
              for (let item2 of item.datas) {
                loop++;
                // 时间点有数据，不再查找
                if (item2.key == this.chartCount[i].field) {
                  yData.push(item2.value);
                  break;
                }
                // 直到最后都没有找到数据，添加空数据
                if (loop == item.datas.length) {
                  yData.push("");
                }
              }
            }

          }
          this.$nextTick(() => {
            this.initChartData(`chart${i - 1}`, tData, xData, yData);
            this.isCreateChart = true;
          })
        }
      }, 1);
    },
    // 初始化图表数据
    initChartData(id, tData, xData, yData) {
      let chart = echarts.init(document.getElementById(id));
      let option = {
        title: {
          text: tData.title,
          subtext: "(" + tData.unit + ")",
          textStyle: {
            color: '#606266'
          }
        },
        color: tData.color,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: {
          type: 'value'
        },
        grid: {
          top: '20%'
        },
        dataZoom: [{
          type: 'inside',
          show: false,
        }],
        toolbox: {
          left: 'center',
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            magicType: {
              type: ['line', 'bar']
            },
            saveAsImage: {}
          }
        },
        series: [{
          data: yData,
          type: 'line',
          smooth: true,
          symbol: 'none',
          itemStyle: {
            normal: {
              lineStyle: {
                width: 4
              }
            }
          }
        }]
      };
      chart.setOption(option);
      if (chart) chart.resize();
    },
    // 监听
    onMsgFun() {
      // 历史数据result
      this.ws.addCallback('history2Ack', (res) => {
        this.loading = false;
        console.log(112, res);
        if (this.$route.name.indexOf("HistoryData") != -1) {
          switch (this.type) {
            case "sensor":
              this.setSensorData(res);
              break;
            case "online":
              this.setOnlineData(res);
            default:
              break;
          }
        }
      })

      // 导出result
      this.ws.addCallback('createHistoryFileAck', (res) => {
        this.loading = false;
        let fileName = res.fileName;
        let fileUrl = res.fileUrl;

        let protocol = window.location.protocol; //返回url的协议部分
        let host = window.location.host; //返回url的主机部分

        // let testHref = "http://192.168.2.102:80/historyDownload/00391WES(2021.04.19)(1).xlsx";
        // window.location.href = testHref;

        window.location.href = res.fileUrl;
        // console.log("download=" + res.fileUrl);

      })
    }
  },
  created() {
    let param = this.$route.query;
    this.type = param.type;
  },
  mounted() {
    this.onMsgFun();
  }
}
</script>

<style lang="scss">
#historyData {
  .chart-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-row-gap: 1%; //行间距
    grid-column-gap: 1%; //列间距

    .chart {
      padding: 5%;
    }
  }

  .typeOnline {
    // 设备状态列
    .el-table th,
    .el-table td {
      &:nth-child(3) {
        text-align: left !important;
        padding-left: 5% !important;
      }
    }
  }

  // 重写element样式
  .el-date-editor--timerange {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .el-radio-group {
    margin-top: -3px;
  }

  .el-radio-button__inner {
    padding: 12px 15px;
  }

  .emty-class {
    color: #909399;
    text-align: center;
    padding: 20px 0;
  }
}
</style>
